import React from "react";
import { Divider, Typography, Grid } from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";

interface PageHeadingProps {
  children?: any;
  text?: string;
}

const useStyles = makeStyles(theme =>
  createStyles({
    pageHeadingWrapper: {
      margin: theme.spacing(3, 0, 3),
    },
    pageHeadingDivider: {
      background: theme.palette.secondary.main,
    },
    pageHeadingText: {
      color: theme.palette.primary.main,
      padding: theme.spacing(1, 0, 1),
      marginBottom: 0,
    },
  }),
);

const PageHeading = ({ children, text }: PageHeadingProps) => {
  const classes = useStyles();

  return (
    <div className={classes.pageHeadingWrapper}>
      <Grid container spacing={2} direction="row">
        <Grid container alignItems="center" item xs={12} sm={children ? 6 : 12}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            className={classes.pageHeadingText}
          >
            {text}
          </Typography>
        </Grid>
        {children && (
          <Grid
            alignItems="center"
            container
            item
            xs={12}
            sm={6}
            justifyContent="center"
          >
            {children}
          </Grid>
        )}
      </Grid>
      <Divider className={classes.pageHeadingDivider} variant="fullWidth" />
    </div>
  );
};

export default PageHeading;
