import React from "react";
import Content from "./Content";
import Header from "./Header";
import ErrorBoundary from "../ErrorBoundary";
// import dynamic from "next/dynamic";
import { IClaims } from "@Lib/auth/session/session";
import { Client } from "@Types";

// const Header = dynamic(() => import("../Header"));
// const Content = dynamic(() => import("../Content"));
// const Footer = dynamic(() => import("../Footer"));

export interface SiteLayoutProps {
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
  user?: IClaims | null;
  client?: Client | null;
  clientLoading?: boolean;
  userLoading?: boolean;
  title?: string;
}

const SiteLayout = ({
  children,
  className,
  title,
  ref,
}: React.PropsWithChildren<SiteLayoutProps>) => {
  return (
    <>
      <Header title={title} />
      <Content className={className} ref={ref} heading={title}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Content>
    </>
  );
};

export default SiteLayout;
