import React from "react";
import Link from "../src/components/Link";
import SiteLayout from "../src/components/layouts/SiteLayout";
import PageHeading from "@Components/PageHeading";
import { Typography } from '@material-ui/core';

const Custom404 = () => {
  return (
    <SiteLayout>
      <PageHeading text="We couldn't find the page you were looking for..."></PageHeading>
      <Typography paragraph variant="body2">
        What we <i>can</i> find is the <strong>truth</strong> behind your
        questionable policy claims.
      </Typography>

      <Link href="/services" color="secondary">
        Check out what we have to offer
      </Link>
    </SiteLayout>
  );
};

export default Custom404;
