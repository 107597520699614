import React from "react";
import { ContainerProps } from "@material-ui/core/Container";
import { Container, Paper } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

interface Props extends ContainerProps {
  children: React.ReactElement;
  heading?: string;
}

const ContainerComponent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  minHeight: 400,
}));

const Content = ({ children, heading, ...rest }: Props) => (
  <Container maxWidth="lg" component={ContainerComponent} {...rest}>
    {children}
  </Container>
);

export default Content;
