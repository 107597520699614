import React from "react";
import logger from "../utils/debug";
import Alert from "@material-ui/lab/Alert";
import { Typography, Divider } from '@material-ui/core';

class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; error: any }
> {
  logger = logger("ErrorBoundary");
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.logger.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Typography variant="h3" component="h2">
            Something went wrong.
          </Typography>

          {/* Don't render error details in production */}
            <>
              <Divider />
              <Alert severity="error">
                We are very sorry, please try again
              </Alert>
            </>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
